<template>
  <div
    class="hint-hover server"
    :class="{
      server_status_on: server.status === 'on',
      server_status_off: server.status === 'off',
      priority_0: parseInt(server.order) === 1,
      priority_1: parseInt(server.order) === 2,
      priority_2: parseInt(server.order) === 3,
      priority_3: parseInt(server.order) === 4,
      priority_4: parseInt(server.order) === 5,
      priority_5: parseInt(server.order) === 6,
    }"
  >
    <router-link
      :to="{ name: 'Server', params: { id: server.id } }"
      class="server__link"
      :aria-label="server.name"
      data-inner-select="server"
      @click="this.$store.dispatch('open_modal', $event)"
    ></router-link>
    <div class="server__status" translate="no">
      <div class="server__state_online server__state_online_on">on</div>
      <div class="server__state_online server__state_online_off">off</div>
    </div>
    <div
      class="server__progress"
      :data-online="server.status === 'on' ? server.online : 0"
    ></div>
    <div class="server__content" translate="no">
      <div class="server__front">
        <div class="server__life life notranslate" data-start-time>
          <div
            class="life__title"
            :class="{
              life__title_new: displayTitle !== lifeTimeAbbr,
            }"
          >
            {{ displayTitle }}
          </div>
          <div class="life__days">
            {{
              displayDays === 1 ? displayDays + " day" : displayDays + " days"
            }}
          </div>
          <div class="life__time">
            {{ displayHours + ":" + displayMinutes + ":" + displaySeconds }}
          </div>
        </div>
        <div class="server__title">
          {{ server.name }}
          <span class="server__name">x{{ server.rate }}</span>
        </div>
      </div>
      <div class="server__back">
        <div class="server__title">Online</div>
        <div class="server__online">
          {{ server.status === "on" ? server.online : 0 }}
        </div>
      </div>
    </div>
    <div class="server__hint hint">
      <div class="hint__btn">
        <div class="hint__arrow"></div>
      </div>
      <div class="hint__list hint_size_large">
        <ServerLink
          v-for="(server_link, index) in server_links"
          :key="index"
          :link="server_link"
          :server_id="server.id"
          :desc-link="server.desc_link"
        ></ServerLink>
      </div>
    </div>
  </div>
</template>

<script>
import { serverInitCircleProgress } from "@/assets/js/servers";
import ServerLink from "@/components/Body/Servers/ServerLink";

export default {
  name: "ServerItem",
  components: { ServerLink },
  props: {
    server: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      main_server_id: 1,
      circle_initiated: false,
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      displayTitle: null,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      lifeTimeAbbr: "Life Time",
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    _start() {
      return this.$props.server.start * 1000;
    },
    _sid() {
      return this.$props.server.id;
    },
    server_links: {
      get() {
        return this.$store.getters.get_server_links;
      },
    },
  },
  methods: {
    minutes_format: (num) => (num < 10 ? "0" + num : num),
    showRemaining() {
      setInterval(() => {
        const now = new Date();
        const end = new Date(this.server.start);
        const started = now.getTime() > end.getTime();

        this.displayTitle = !started
          ? this.monthNames[end.getMonth()] + " " + end.getDate()
          : this.lifeTimeAbbr;

        const distance = Math.abs(end.getTime() - now.getTime());

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayDays = days;
        this.displayHours = this.minutes_format(hours);
        this.displayMinutes = this.minutes_format(minutes);
        this.displaySeconds = this.minutes_format(seconds);
      }, 1000);
    },
  },
  updated() {
    if (!this.circle_initiated) {
      setTimeout(() => serverInitCircleProgress(), 500);
    }
    this.circle_initiated = true;
  },
  mounted() {
    this.$store.dispatch("fetch_modal_links");
    this.showRemaining();
  },
};
</script>

<style scoped></style>
